import React from 'react';
import { useTranslation } from 'react-i18next';

import error404 from '../assets/svg/illustrations/error-404.svg';
import errorNumber404 from '../assets/svg/illustrations/error-number-404.svg';
import LocalizedLink from '../components/LocalizedLink';
import SEO from '../components/Seo';

const NotFoundPage = ({ location }) => {
  const { i18n, t } = useTranslation();

  return (
    <>
      <SEO
        title={t('meta.404.title')}
        lang={i18n.language}
        description={t('meta.home.description')}
        pathname={location.pathname}
      />
      <div className="d-lg-flex" style={{ backgroundImage: `url(${error404})` }}>
        <div className="container d-lg-flex align-items-lg-center min-vh-lg-100 space-bottom-4 space-top-3">
          <div className="w-lg-60 w-xl-50">
            <div className="mb-4">
              <img className="max-w-23rem mb-3" src={errorNumber404} alt="SVG Illustration" />
              <p className="lead">Oops! {t('notFoundPage.title')}</p>
            </div>

            <LocalizedLink
              to="/"
              className="btn btn-wide btn-primary transition-3d-hover text-capitalize"
            >
              {t('notFoundPage.subtitle')}
            </LocalizedLink>
          </div>
        </div>
      </div>
    </>
  );
};

export default NotFoundPage;
